<template>
  <div class="md:-mt-12">
    <div class="fixed top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12 z-0" />
    <div class="pt-10 px-8 md:px-14">
      <div class="rounded-t bg-white mb-0 px-6 py-6 relative z-10">
        <div class="text-center flex justify-between">
          <div class="flex">
            <h6 class="text-blueGray-700 text-xl font-bold">
              {{ $t("Alerts") }}
            </h6>
          </div>
        </div>
      </div>
      <div
        style="height: calc(100vh - 255px)"
        class="relative overflow-hidden flex flex-col min-w-0 break-words w-full mb-6 mx-auto shadow-lg rounded-lg rounded-t-none bg-blueGray-100 border-0"
      >
        <div class="flex">
          <div class="w-64 border-r">
            <h3 class="px-6 font-semibold mt-3">
              {{ $t("general") }}
            </h3>
            <div
              class="bg-white px-3 py-2 my-3 mx-4 relative font-semibold cursor-pointer rounded"
              :class="{
                'border-l-8 border-emerald-600': generalSetting == true
              }"
              @click="selectGeneralSetting"
            >
              {{ $t("settings") }}
            </div>
            <h3 class="px-6 font-semibold mt-3">
              {{ $t("hubs") }}
            </h3>
            <div class="overflow-scroll" style="height: calc(100vh - 293px)">
              <div
                v-for="(hub, index) in hubs"
                :key="hub.id"
                class="bg-white px-3 py-2 my-3 mx-4 relative font-semibold cursor-pointer rounded"
                :class="{
                  'border-l-8 border-emerald-600':
                    index == selectedHubIndex && generalSetting != true
                }"
                @click="selectHubIndex(index)"
              >
                {{ hub.name }}
              </div>
            </div>
          </div>
          <div class="top-0" style="width: calc(100% - 16rem)">
            <h3 class="font-semibold text-gray-500 px-5 py-3 bg-white border-t">
              {{ selectedHubName }}
            </h3>
            <template v-if="generalSetting == true">
              <div class="overflow-scroll" style="height: calc(100vh - 360px)">
                <div class="border-b py-3 px-5 flex items-center">
                  <label class="text-gray-600 w-72">
                    {{ $t("ticket creation callback") }}
                  </label>
                  <input
                    class="w-full px-4 py-2"
                    v-model="alerts.schema.ticket_creation_callback"
                  />
                </div>
              </div>
            </template>
            <template v-if="generalSetting == false">
              <div class="overflow-scroll" style="height: calc(100vh - 360px)">
                <div
                  class="border-b py-3 px-5 flex items-center"
                  :class="{ 'border-t': i == 0 }"
                  v-for="(alert, i) in alertForm"
                  :key="alert.key"
                >
                  <label class="text-gray-600 w-72">
                    {{ alert.label }}
                  </label>
                  <input
                    :type="alert.type"
                    class="w-full px-4 py-2"
                    v-model="alert.value"
                  />
                </div>
              </div>
            </template>

            <div class="border-t px-5 py-3 bg-white overflow-hidden">
              <button
                class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                @click="handleSave"
              >
                {{ $t("save") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Alerts",
  components: {},
  data() {
    // let alerts = {
    //   hubID: {
    //     slack_bike_damage_alert: "",
    //     slack_checkout_alert: ""
    //   }
    // };
    return {
      alerts: {},
      selectedHubIndex: 0,
      generalSetting: true,
      alertForm: [
        {
          label: "slack asset damage alerts",
          key: "slack_damage_alert",
          value: "",
          type: "url"
        },
        {
          label: "slack booking alerts",
          key: "slack_booking_alert",
          value: "",
          type: "url"
        }
      ]
    };
  },
  computed: {
    ...mapState("hubs", ["hubs"]),
    selectedHub() {
      return this.hubs[this.selectedHubIndex];
    },
    selectedHubName() {
      if (this.generalSetting == true) {
        return "general settings";
      }
      return this.selectedHub?.name;
    }
  },
  methods: {
    selectGeneralSetting() {
      this.generalSetting = true;
      if (!this.alerts.schema?.ticket_creation_callback) {
        this.$set(this.alerts.schema, "ticket_creation_callback", "");
      }
    },
    selectHubIndex(index) {
      this.generalSetting = false;
      this.selectedHubIndex = index;
      if (!this.alerts?.schema) {
        this.alerts.schema = {};
      }
      if (!this.alerts.schema?.[this.selectedHub.id]) {
        this.$set(
          this.alerts.schema,
          this.selectedHub.id,
          this.alertForm.reduce((accu, alert) => {
            accu[alert.key] = "";
            return accu;
          }, {})
        );
      }
      this.alertForm.forEach(alert => {
        alert.value = this.alerts.schema[this.selectedHub.id][alert.key];
      });
    },
    handleSave() {
      this.alerts.schema[this.selectedHub.id] = this.alertForm.reduce(
        (accu, alert) => {
          accu[alert.key] = alert.value;
          return accu;
        },
        {}
      );
      this.$store.dispatch("settings/setAlertSettings", {
        setting: {
          type: "alerts",
          ...this.alerts
        }
      });
    }
  },
  mounted() {
    this.$store.dispatch("hubs/getAllHubs");
    this.$store.dispatch("settings/getSettings", {
      setting: "alerts",
      cb: alerts => {
        this.alerts = alerts;
        this.selectHubIndex(this.selectedHubIndex);
      }
    });
  }
};
</script>
